<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <account-settings-account></account-settings-account>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline} from '@mdi/js'
import {ref} from '@vue/composition-api'

import AccountSettingsAccount from './AccountSettingsAccount.vue'

export default {
  components: {
    AccountSettingsAccount,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      {title: 'Account', icon: mdiAccountOutline},
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>
