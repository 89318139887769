<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-avatar
        rounded
        size="120"
        class="me-6"
      >
        <v-img
          :src="preview"
          lazy-src="https://www.fantasykombat.com/favicon-32x32.png"
        ></v-img>
      </v-avatar>

      <!-- upload photo -->
      <div>
        <v-btn
          outlined
          small
          class="me-3 mt-7"
          @click="$refs.refInputEl.click()"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Upload new photo</span>
        </v-btn>
        <input
          ref="refInputEl"
          type="file"
          accept="image/*"
          :hidden="true"
          @change="previewImage"
        />
        <p class="text-sm mt-2 caption">
          Supported formats: JPG, GIF or PNG. Max size of 1MB
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="name"
              label="Name"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="email"
              label="E-mail"
              dense
              outlined
              disabled
            >
              {{ email }}
            </v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              label="Dapper Account"
              dense
              disabled
              :value="dapperAddress"
              outlined
            >
              {{ dapperAddress }}
            </v-text-field>
            <v-btn
              class="mt-2"
              small
              outlined
              to="/wallet"
            >
              Update Wallet
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="status"
              dense
              outlined
              label="Status"
              :items="status"
              disabled
            >
              {{ status }}
            </v-select>
            <v-btn
              class="mt-2"
              :disabled="disableReset"
              small
              outlined
              @click="passwordReset"
            >
              {{ resetText }}
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              outlined
              color="success"
              class="me-3 mt-4"
              @click="saveProfile"
            >
              Save changes
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
import dbFunctions from '@/db'

export default {

  setup() {
    return {
      name: '',
      preview: '',
      file: '',
      status: ['Active', 'Inactive', 'Pending', 'Closed'],
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
  data() {
    return {
      disableReset: false,
      changeEmailReset: false,
      resetText: 'Request password reset email',
      changeEmailText: 'Request password reset email',
    }
  },
  computed: {
    email: {
      get() {
        return this.$store.state.user.email
      },
    },
    dapperAddress: {
      get() {
        return this.$store.state.user.profile?.dapperAddress
      },
    },
    photoURL: {
      get() {
        return this.$store.state.user.photoURL
      },
      set(value) {
        this.preview = value
      },
    },
    displayName: {
      get() {
        return this.$store.state.user.displayName
      },
      set(value) {
        this.name = value
      },
    },
  },
  mounted() {
    this.name = this.displayName
    this.preview = this.photoURL
  },
  methods: {
    previewImage(e) {
      // eslint-disable-next-line prefer-destructuring
      this.file = e.target.files[0]
      this.preview = URL.createObjectURL(this.file)
      this.photoURL = this.preview
    },
    saveProfile() {
      const profile = {
        name: this.name,
        file: this.file,
      }
      this.$store
        .dispatch('saveProfile', profile)
        .catch(err => {
          console.log(err)
        })
    },
    passwordReset() {
      dbFunctions.sendPasswordReset(this.$store.state.user.email)
      this.disableReset = true
      this.resetText = 'Password email password sent'
    },
  },
}
</script>
